

















import { Component, Vue } from 'vue-property-decorator'
import { Toast } from 'vant'
import { submitFeedback, getHelpList } from '@/pages/user/api'
import { CommonModule } from '@/store/modules/common'

import IOSUpload from './components/IOSUpload.vue'
import QuestionList from './components/QuestionList.vue'
import { isIOS } from '@/common/utils/device/getSystemInfo'
import { getQuestionInfo } from '@/pages/videoGame/api'
@Component({
  name: 'HelpFeedback',
  components: { QuestionList, IOSUpload }
})
export default class HelpFeedback extends Vue {
  textarea = ''
  isIOS = isIOS
  fileList: Array<UploadFileList> = []
  repeatClick = true
  get userName() {
    return CommonModule.userName
  }

  goCommit() {
    this.$router.push('/help-commit')
  }
  onChange(list: Array<UploadFileList>) {
    this.fileList = list
  }
  helpList: any = []
  async init() {
    const { data } = await getQuestionInfo({ effectType: 2 })
    this.helpList = data
  }
  mounted() {
    this.init()
  }
}
