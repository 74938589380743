


























import { Component, Vue, Prop } from 'vue-property-decorator'
import { getAppLanguage } from '@/common/utils'
@Component({
  name: 'QuestionList'
})
export default class extends Vue {
  @Prop({
    required: true
  })
  list!: Array<any>
  @Prop({
    required: true,
    default: 'feedback'
  })
  type!: 'help' | 'feedback'
  //list: Array<HelpClassList> = []
  language = getAppLanguage()
  async handleItem(item: any) {
    if (item.jumpType == 2) {
      window.location.href = item.content
      return
    }

    if (item.jumpType == 1) {
      this.$router.push(`/help-detail?title=${item.title}&id=${item.id}`)
    }
  }
  nameText(name: string, nameUg?: string) {
    if (this.language == 'uyghur' && nameUg) {
      return nameUg
    } else {
      return name
    }
  }
}
